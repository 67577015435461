import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';


// import { FrontendLayoutComponent } from './pages/frontend/layouts/frontend-layout/frontend-layout.component';
// import { HomeComponent } from './pages/frontend/home/home.component';
// import { AboutComponent } from './pages/frontend/about/about.component';
// import { ContactComponent } from './pages/frontend/contact/contact.component';

const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },

  {
    path: '',
    loadChildren: () => import('./pages/frontend/frontend.module').then((m) => m.FrontendModule),
  },
  {
    path: 'backend',
    loadChildren: () => import('./pages/backend/backend.module').then((m) => m.BackendModule),
  }
];



@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
